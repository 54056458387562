import { buildSchema, EntityCallbacks } from "@camberi/firecms";
import { organizationSchema } from "./organization_schema";
import { Project } from "../types";


export const projectCallbacks: EntityCallbacks = {
    onPreSave: async ({
        schema,
        path,
        entityId,
        values,
        status,
        context
    }) => {
        console.log("onPreSave", values);

        //set program_ids field from program_refs
        var program_ids: string[] = [];
        if (!values.program_refs) values.program_refs = [];
        values.program_refs.forEach((element: any) => {
            program_ids.push(element.id)
        });
        values.program_ids = program_ids

        if (!values.about.es) values.about.es = "";
        if (!values.overview.es) values.overview.es = "";
        if (!values.species_description.es) values.species_description.es = "";
        if (!values.why_its_great.es) values.why_its_great.es = "";
        if (!values.why_its_great.en) values.why_its_great.en = "";

        //TODO: Sera mejor eliminar los elementos vacios en vez de dar error?
        if (values.data_sources) {
            values.data_sources.forEach((element: any) => {
                if (Object.values(element).filter((e: any) => { return e !== null }).length === 0) {
                    throw Error("Data Sources no puede tener elementos vacios");
                }
            })
        }

        let new_values = await context.dataSource.fetchEntity({
            path: values.organization_ref.path,
            entityId: values.organization_ref.id,
            schema: organizationSchema
        }).then((entity) => { return ({ ...values, organization: entity.values, organization_id: entity.id }) })
        console.log('project new values', new_values);
        return new_values;
    },
    onSaveSuccess: async (props) => {
        const prev = props.previousValues!
        const actual = props.values;
        const dataSource = props.context.dataSource;

        // El proyecto debe actualizar tanto la organizacion anterior como la nueva
        for (let data of [actual, prev]) {
            let organization_id = data.organization_ref.id;
            let projects = await dataSource.fetchCollection({
                "path": "Project",
                "schema": projectSchema,
                "filter": { "organization_id": ["==", organization_id] }
            })

            let project_ids: string[] = [];
            for (let i in projects) {
                project_ids.push(projects[i].id);
            }

            let org = await dataSource.fetchEntity({
                path: "Organization",
                schema: organizationSchema,
                entityId: organization_id
            })
            org.values.project_ids = project_ids

            await dataSource.saveEntity({
                "entityId": organization_id,
                "path": "Organization",
                "schema": organizationSchema,
                "values": org.values,
                "status": "existing"
            })
        }
        console.log("onSaveSuccess", props);
    }
};

export const projectSchema = buildSchema<Project>({
    name: "Project",
    customId: true,
    properties: {

        organization_ref: {
            title: "Organization ID",
            description: "Organization who owns the project",
            validation: { required: true },
            dataType: "reference",
            path: "Organization",
            previewProperties: ["name"]
        },
        name: {
            title: "Name",
            description: "Name of the project in the app.",
            validation: { required: true },
            dataType: "string"
        },
        overview: {
            title: "Overview",
            dataType: "map",
            description: "Project short description.",
            properties: {
                en: {
                    title: "EN",
                    dataType: "string",
                    validation: { required: true, max: 200 },
                    config: {
                        multiline: true
                    }
                },
                es: {
                    title: "ES",
                    dataType: "string",
                    validation: { max: 200 },
                    config: {
                        multiline: true
                    }
                }
            }
        },
        about: {
            title: "About",
            description: "Project long description.",
            validation: { required: true },
            dataType: "map",
            properties: {
                en: {
                    title: "EN",
                    dataType: "string",
                    validation: { required: true, max: 2000 },
                    config: {
                        multiline: true
                    }
                },
                es: {
                    title: "ES",
                    dataType: "string",
                    validation: { max: 2000 },
                    config: {
                        multiline: true
                    }
                }
            }
        },
        why_its_great: {
            title: "Why it's great",
            description: "Why this project is great",
            validation: { required: false },
            dataType: "map",
            properties: {
                en: {
                    title: "EN",
                    dataType: "string",
                    validation: { max: 2000 },
                    config: {
                        multiline: true
                    }
                },
                es: {
                    title: "ES",
                    dataType: "string",
                    validation: { max: 2000 },
                    config: {
                        multiline: true
                    }
                }
            }
        },
        area: {
            description: "Project total area.",
            dataType: "number",
            disabled: true
        },
        // TODO: species_description NO existe en el modelo actual (SN validará que se hace)
        species_description: {
            title: "Species description",
            description: "Featured species on the project",
            validation: { required: true },
            dataType: "map",
            properties: {
                en: {
                    title: "EN",
                    dataType: "string",
                    validation: { required: true },
                    config: {
                        multiline: true
                    }
                },
                es: {
                    title: "ES",
                    dataType: "string",
                    config: {
                        multiline: true
                    }
                }
            }
        },
        location_string: {
            description: "Project location",
            dataType: "string",
            disabled: true
        },
        // icon_uri: ({ entityId }) => ({
        //     title: "Icon URI",
        //     description: "Icon file for the project.",
        //     validation: { required: false },
        //     dataType: "string",
        //     config: {
        //         storageMeta: {
        //             mediaType: "image",
        //             storagePath: (context) => {
        //                 return "Project/" + context.entityId;
        //             },
        //             fileName: (context) => {
        //                 const re = /(?:\.([^.]+))?$/;
        //                 let extArray = re.exec(context.file.name);
        //                 let extension = null
        //                 if (extArray) {
        //                     extension = extArray[1]
        //                 }
        //                 return 'icon' + (extension ? '.' + extension : '');
        //             },
        //             acceptedFiles: ["image/jpeg", "image/png"],
        //             storeUrl: true
        //         }
        //     },
        //     disabled: !entityId && { disabledMessage: 'Please create project before load files' }
        // }),
        // // TODO: este campo no está lleno en el modelo
        // thumbnail_uri: ({ entityId }) => ({
        //     title: "Thumbnail URI",
        //     description: "Thumbnail file for the project",
        //     validation: { required: false },
        //     dataType: "string",
        //     config: {
        //         storageMeta: {
        //             mediaType: "image",
        //             storagePath: (context) => {
        //                 return "Project/" + context.entityId;
        //             },
        //             fileName: (context) => {
        //                 const re = /(?:\.([^.]+))?$/;
        //                 let extArray = re.exec(context.file.name);
        //                 let extension = null
        //                 if (extArray) {
        //                     extension = extArray[1]
        //                 }
        //                 return 'thumbnail' + (extension ? '.' + extension : '');
        //             },
        //             acceptedFiles: ["image/jpeg", "image/png"],
        //             storeUrl: true
        //         }
        //     },
        //     disabled: !entityId && { disabledMessage: 'Please create project before load files' }
        // }),
        boundary_uri: ({ entityId }) => ({
            title: "Boundary URI",
            description: "Boundary of the project (KML, KMZ or ZIP (shapefile))",
            validation: { required: false },
            dataType: "string",
            config: {
                storageMeta: {
                    storagePath: (context) => {
                        return "Project/" + context.entityId;
                    },
                    fileName: (context) => {
                        const re = /(?:\.([^.]+))?$/;
                        let extArray = re.exec(context.file.name);
                        let extension = null
                        if (extArray) {
                            extension = extArray[1]
                        }
                        return 'boundary' + (extension ? '.' + extension : '');
                    },
                    storeUrl: true
                }
            },
            disabled: !entityId && { disabledMessage: 'Please create project before load files' }
        }),
        // Componente opcional
        contact: {
            title: "Contact Information",
            description: "Contact information and Social Media links",
            validation: { required: false },
            dataType: "map",
            properties: {
                website: {
                    title: "Website",
                    dataType: "string",
                    validation: { url: true }
                },
                phone: {
                    title: "Phone number",
                    description: "Must be in the following format: +1 1 11111111",
                    dataType: "string",
                    validation: {
                        // eslint-disable-next-line
                        matches: /^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/
                    }
                },
                email: {
                    title: "Email",
                    dataType: "string",
                    validation: { email: true }
                },
                social_media: {
                    title: "Social media links.",
                    dataType: "array",
                    oneOf: {
                        typeField: "code",
                        valueField: "url",
                        properties: {
                            facebook: {
                                dataType: "string",
                                title: "Facebook",
                                config: { url: true },
                                validation: { url: true }
                            },
                            instagram: {
                                dataType: "string",
                                title: "Instagram",
                                config: { url: true },
                                validation: { url: true }
                            },
                            linkedin: {
                                dataType: "string",
                                title: "LinkedIn",
                                config: { url: true },
                                validation: { url: true }
                            },
                            twitter: {
                                dataType: "string",
                                title: "Twitter",
                                config: { url: true },
                                validation: { url: true }
                            },
                            youtube: {
                                dataType: "string",
                                title: "YouTube",
                                config: { url: true },
                                validation: { url: true }
                            },
                        }
                    }
                }
            }
        },
        keywords: {
            title: "Project Keywords",
            validation: { required: false },
            dataType: "array",
            description: "Words used to find the project in the app.",
            of: {
                dataType: "string"
            }
        },
        program_refs: {
            title: "Program",
            dataType: "array",
            validation: { max: 3 },
            of: {
                dataType: "reference",
                path: "Program",
                previewProperties: ["name", "description"]
            }
        },

        established: {
            title: "Established (year)",
            description: "Starting date of the project.",
            dataType: "number"
        },
        data_sources: {
            title: "Data sources",
            dataType: "array",
            description: "Data sources for the project",
            of: {
                title: "Source",
                dataType: "map",
                properties: {
                    text: {
                        dataType: "string",
                        title: "Source description",
                    },
                    url: {
                        dataType: "string",
                        title: "Source URL",
                        config: { url: true },
                        validation: { url: true },
                    },
                },
            },
        },
        cell_avg_price: {
            description: "Average cell price for this project",
            dataType: "number",
            title: "Cell average price",
            disabled: true
        },
        species: {
            dataType: "array",
            title: "Species",
            description: "Species present inside the project's boundary.",
            of: {
                dataType: "reference",
                path: "Species"
            }
        },
        status: {
            title: "Status",
            description: "Project status.",
            validation: { required: true },
            config: {
                enumValues: {
                    DRAFT: { label: "Draft", color: "grayLight" },
                    READY: { label: "Ready", color: "cyanLight" },
                    PENDING: { label: "Pending", color: "yellowLight", disabled: true },
                    ACTIVE: { label: "Active", color: "greenLight" },
                    DISABLED: { label: "Disabled", color: "redLight" }
                }
            },
            dataType: "string"
        },
        bounds: {
            description: "Project bounding box coordinates",
            dataType: "map",
            disabled: true,
            properties: {
                bottom: {
                    description: "Bottom limit",
                    dataType: "number"
                },
                left: {
                    description: "Left limit",
                    dataType: "number"
                },
                right: {
                    description: "Right limit",
                    dataType: "number"
                },
                top: {
                    description: "Top limit",
                    dataType: "number"
                }

            }
        },
        /*organization: {
            description:"Custodian organization info",
            dataType:"object",
            properties:{
                certified:{
                    description:"Organization is certified",
                    dataType:"boolean"
                },
                location_string:{
                    description:"Geographic area",
                    dataType:"string"
                },
                location:{
                    description:"Not sure",
                    dataType:"object",
                    properties:{
                        lat:{
                            description:"Latitude",
                            dataType:"number"
                        },
                        lng:{
                            description:"Longitude",
                            dataType:"number"
                        }
                    }
                },
                name:{
                    description:"Organization name",
                    dataType:"string"
                },
                icon_src:{
                    description:"Organization icon images and hash",
                    dataType:"object",
                    properties:{
                        hash:{
                            description:"Image BlurHash",
                            dataType:"string"
                        },
                        extension:{
                            description:"Image extension",
                            dataType:"string"
                        },
                        uri:{
                            description:"Images uri",
                            dataType:"string"
                        }
                    }
                },
                icon_uri:{
                    description: "Icon file for the project",
                    dataType:"string"
                }
            },*/
        featured_cell_ids: {
            title: "Array of featured cells",
            description: "Array of cell that belongs to a featured place inside this project.",
            dataType: "array",
            disabled: true,
            of: {
                dataType: "string"
            }

        },
        // timestamp: {
        //     title: "Timestamp",
        //     description: "Timestamp when the data was captured",
        //     dataType: "timestamp"
        // },
        featured: {
            title: "Featured",
            description: "Represent if this project could appear in explore or not.",
            dataType: "boolean"
        },
        timestamp_last_update: {
            title: "Timestamp last update",
            description: "Last time the data was updated",
            dataType: "timestamp",
            autoValue: "on_update",
            disabled: true
        },
        location: {
            description: "Location",
            dataType: "map",
            disabled: true,
            properties: {
                lat: {
                    description: "Latitude",
                    dataType: "number"
                },
                lng: {
                    description: "Longitude",
                    dataType: "number"
                }
            }
        }
    }
});
